import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Button from '../Button';
//import Carousel from '../Carousel';
import {dark} from '../../styles/Themes';

const Section = styled.section`
min-height: 80vh;
width: 100%;
background-color: ${props => props.theme.text};
//color: ${props => props.theme.body};
display: flex;
justify-content: center;
align-items: center;
position: relative;
`
const Container = styled.div`
width: 75%;
margin: 0 auto;
/* background-color: lightblue; */
//display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
@media (max-width: 70em) {
  width: 85%;
}

@media (max-width: 64em) {
     width: 100%;
     flex-direction: column;
     &>*:last-child{
      width: 80%;
     }
}

@media (max-width: 40em) {
     &>*:last-child{
      width: 90%;
     }
}
`
const Box = styled.div`
width: 50%;
height: 100%;
min-height: 60vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media (max-width: 40em) {
    min-height: 50vh;
}
`
const Title = styled.h2`
font-size: ${props => props.theme.fontxxl};
text-transform: capitalize;
color: ${props => props.theme.body};
align-self: flex-start;
text-align: center;
width: 80%;
margin: 0 auto;

@media (max-width: 64em) {
    width: 100%;
    text-align: center;
}
@media (max-width: 40em) {
  font-size: ${(props) => props.theme.fontxl};
}
@media (max-width: 30em) {
  font-size: ${(props) => props.theme.fontlg};
}
`

const SubText = styled.p`
font-size: ${props => props.theme.fontlg};
color: ${props => props.theme.body};
align-self: flex-start;
text-align: center;
width: 80%;
margin: 1rem auto;
font-weight:400;
@media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontmd};
} 
@media (max-width: 40em) {
  font-size: ${(props) => props.theme.fontmd};
}
@media (max-width: 30em) {
  font-size: ${(props) => props.theme.fontsm};
}
`
const SubTextLight = styled.p`
font-size: ${props => props.theme.md};
color: ${props => `rgba(${props.theme.bodyRgba}, 0.6)`};
align-self: flex-start;
text-align: justify;
width: 80%;
margin: 1rem auto;
font-weight: 400;

@media (max-width: 64em) {
    width: 100%;
    text-align: justify;
    font-size: ${(props) => props.theme.fontsm};
}
@media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontsm};
}
@media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxs};
}
`

const ButtonContainer = styled.div`
    width: 80%;
    margin: 1rem auto;
    align-self: flex-start;
    text-align: center;
    /*
    //    display: flex;
    //@media (max-width: 64em) {
    //width: 100%;
    //button{
    //  margin: 0 auto;
   // }
   */
    @media (max-width: 64em) {
        align-self: center;
        text-align: center;
        button{
            margin: 0 auto;
        }

  }
`
/**TODO: Disable Carousel <Box> <Carousel /> </Box> Until after launch/reveal so you can show the NFTs in carousel slides again.  */
const About = () => {
  return (
    <Section id="about">
        <Container>
          <Title>
          The Scrambled NFT
          </Title>  
          <SubText>
          The Scrambled Collection is a private collection of unique digital collectibles. This will be the genesis collection of our other collections to come. 
          </SubText>
          <SubTextLight>
          A collection of over 1000+ designs, each NFT is unique and comes with a membership to an exclusive group of successful investors, loyal fans and philanthropist. 
          Join an ambitious ever-growing community with multiple benefits and utilities. Owners of this collection will be eligible whitelister to future 
          collections we will be launching. The Scrambled are stored as tokens on the Solana blockchain and hosted on IPFS.
          </SubTextLight>
          <ButtonContainer>
          <ThemeProvider theme={dark}>
          <Button text="JOIN OUR DISCORD" link="https://discord.gg/cFjUcV7k59" />
          </ThemeProvider>
          </ButtonContainer>
        </Container>
     </Section>
  );
}

export default About;
