import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Button from '../Button';
//import Carousel from '../Carousel';
import {dark} from '../../styles/Themes';

const Section = styled.section`
min-height: 80vh;
width: 100%;
background-color: ${props => props.theme.text};
//color: ${props => props.theme.body};
display: flex;
justify-content: center;
align-items: center;
position: relative;
`
const Container = styled.div`
width: 75%;
margin: 0 auto;
/* background-color: lightblue; */
//display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
@media (max-width: 70em) {
  width: 85%;
}

@media (max-width: 64em) {
     width: 100%;
     flex-direction: column;
     &>*:last-child{
      width: 80%;
     }
}

@media (max-width: 40em) {
     &>*:last-child{
      width: 90%;
     }
}
`
const Box = styled.div`
width: 50%;
height: 100%;
min-height: 60vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media (max-width: 40em) {
    min-height: 50vh;
}
`
const Title = styled.h2`
font-size: ${props => props.theme.fontxxl};
text-transform: capitalize;
color: ${props => props.theme.body};
align-self: flex-start;
text-align: center;
width: 80%;
margin: 0 auto;

@media (max-width: 64em) {
    width: 100%;
    text-align: center;
}
@media (max-width: 40em) {
  font-size: ${(props) => props.theme.fontxl};
}
@media (max-width: 30em) {
  font-size: ${(props) => props.theme.fontlg};
}
`

const SubText = styled.p`
font-size: ${props => props.theme.fontlg};
color: ${props => props.theme.body};
align-self: flex-start;
text-align: center;
width: 80%;
margin: 1rem auto;
font-weight:400;
@media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontmd};
} 
@media (max-width: 40em) {
  font-size: ${(props) => props.theme.fontmd};
}
@media (max-width: 30em) {
  font-size: ${(props) => props.theme.fontsm};
}
`
const SubTextLight = styled.p`
font-size: ${props => props.theme.md};
color: ${props => `rgba(${props.theme.bodyRgba}, 0.6)`};
align-self: flex-start;
text-align: justify;
width: 80%;
margin: 1rem auto;
font-weight: 400;

@media (max-width: 64em) {
    width: 100%;
    text-align: justify;
    font-size: ${(props) => props.theme.fontsm};
}
@media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontsm};
}
@media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxs};
}
`

const ButtonContainer = styled.div`
    width: 80%;
    margin: 1rem auto;
    align-self: flex-start;
    text-align: center;
    /*
    //    display: flex;
    //@media (max-width: 64em) {
    //width: 100%;
    //button{
    //  margin: 0 auto;
   // }
   */
    @media (max-width: 64em) {
        align-self: center;
        text-align: center;
        button{
            margin: 0 auto;
        }

  }
`
/**TODO: Disable Carousel <Box> <Carousel /> </Box> Until after launch/reveal so you can show the NFTs in carousel slides again.  */
/**TODO: Redundant Button below section removed.... <ButtonContainer><ThemeProvider theme={dark}> </ThemeProvider> </ButtonContainer>**/

const Adventure = () => {
  return (
    <Section id="theadventure">
        <Container>
          <Title>
          The Adventure
          </Title>  
          <SubText>
          My name is Sulaiman and This...is my personal crypto adventure... 
          </SubText>
          <SubTextLight>
          Towards the end of 2020, I came across a promising crypto project called thenewboston blockchain headed by popular youtuber Bucky Roberts. Bucky has been an inspiration for me and many other computer enthusiast/developers in developing countries like mine. He showed us the way in programming back when there was no online tutorials and books were cost to purchase or not readily available. I was so happy that he was the face behind the blockchain project and I had no doubt it would be a valid prospect, I invested my savings into the project with the hope that I'd get something big in return. I was in with over $6,000.
          </SubTextLight>
          <SubTextLight>
          It was all going well until sometime in early 2022(I think March) Bucky though not happy with his own decision, decided to pull the plug on the project due to some ideological differences with some community member as regards the project's future. He did not actually end the project immediately the issues started but tried to talk to community members about a better way forward but since no reasonable conclusion was reached he took a break from the project and that was the end. Not that I am trying to put the blame on Bucky or anyone involved in the project but it was a great loss for us all even for Bucky has he has dedicate his time, money and resources into it. It was a huge loss and disappointment for me and many others who had high hopes for the project. Unfortunately for me it was more personal and I was unable to recover my money from it. 
          </SubTextLight>
          <SubTextLight>
          In July of that same year, I finally got an admission offer for Msc Artificial Intelligence and Data Science. An admission that I'd been failing to get since 2019. It was all mixed feelings as I was not even sure if it was good news or bad. On one hand, I had just lost money that I had been saving for this program to a crypto project and on the other hand I just got what I'd been chasing for years now. 
          The irony of it all.
          </SubTextLight>
          <SubTextLight>
          I cried my eyes out wondering how I was so stupid as to trust a project and invest my life savings then lose it all. In the end there is nothing I could have done.
          Due to me not having money I decided to defer my admission to September 2023, it was a hard decision but it was either that or lose the opportunity. I had hope that I'd be able to raise the money before the next admission date. Unfortunately, things did not go as planned since I was unable to secure any good paying job through out the period. 
          </SubTextLight>
          <SubTextLight>
          I have been nurturing an NFT idea since thenewboston project but I was waiting till the project launched of which, sadly... it never did. I eventually decided to continue on the project sometime in August 2022 only this time using the Solana platform. With the hope that I would be able to raise the money I lost, I started the project and by November it was all ready. 
          </SubTextLight>
          <SubTextLight>
          As of now with another resumption date fast approaching, I am using this medium to plead with everyone person out there to support my cause in anyway possible even if it means sharing my story. 
          </SubTextLight>
          <SubTextLight>
          I am also pleading with people who have the capability to mint this NFT collection to please do so as this is the only way I can raise money. I promise to make your contribution worth it in the nearest future. 
          </SubTextLight>
          <SubTextLight>
          I know there is no actual roadmap for incentives yet but I will make sure the project lives on to help others like me who might be facing the same situation if this is successful and I hope people show me kindness by Minting it.
          </SubTextLight>
          <SubTextLight>
          I am to resume in September and still do not have enough funds for tuition.
          </SubTextLight>
          <SubTextLight>
          Thank you and God bless.
          </SubTextLight>

        </Container>
     </Section>
  );
}

export default Adventure;
