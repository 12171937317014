import React, { useLayoutEffect } from 'react';
import gsap from 'gsap';
import { useRef } from 'react';
import ScrollTrigger from 'gsap/ScrollTrigger';
import styled from 'styled-components';
import Accordion from '../Accordion';
//import {AccordionList} from 'accordion-collapse-react-native'
//import {AccordionComponent, AccordionItemDirective, AccordionItemsDirective} from '@syncfusion/ej2-reactnavigations';

const Section = styled.section`
min-height: auto;
width: 100vw;
background-color: ${props => props.theme.text};
position: relative;
color: ${props => props.theme.body};
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`
const Title = styled.h1`
font-size: ${(props) => props.theme.fontxxl};
text-transform: capitalize;
color: ${(props) => props.theme.body};
margin: 1rem auto;
border-bottom: 2px solid ${(props) => props.theme.carouselColor};
width: fit-content;
`
const Container = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-content: center;

@media (max-width: 64em) {
  width: 80%;
}
@media (max-width: 48em) {
  width: 90%;
  flex-direction: column;

  &>*:last-child{
    &>*:first-child{
      margin-top: 0;
    }
  }
}
`
const Box = styled.div`
width: 45%;

@media (max-width: 64em) {
  width: 90%;
  align-self: center;
}
`



const Faq = () => {
  const ref = useRef(null);
  gsap.registerPlugin(ScrollTrigger);
  useLayoutEffect(() => {
    let element = ref.current;
    ScrollTrigger.create({
      trigger:element,
      start:'top top',
      end:'bottom top',
      pin: true,
      pinSpacing: false,
      scrub: true,
      //markers: true,

    })
    return() => {
      ScrollTrigger.kill();
    }
  }, []) 
     /**      <AccordionComponent expandMode='single'></AccordionComponent>  
             <AccordionList
              list={this.state.list}
              header={this._head}
              body={this._body}
              keyExtractor={item => `${item.id}`}
            />
      */

  return (
    <Section ref={ref} id="faq">
        <Title> FAQs </Title>
        <Container>
          <Box>  
            <Accordion title="WHERE CAN I VIEW MY NFTS?">
            Once minted you can view your NFT in the associated wallet use for minting and you can also view it on your  Opensea and onchain with either solscan or soleye.
            </Accordion>
            <Accordion title="WHAT IS THE METAVERSE?">
            A metaverse is a network of 3D virtual worlds focused on social connection. In futurism and science fiction, it is often described as a hypothetical iteration of the Internet as a single, universal virtual world that is facilitated by the use of virtual and augmented reality headsets.
            </Accordion>
            <Accordion title="WHY DO WE NEED ROYALTIES?">
            We have the ambition to organize multiple events around the world in order to strengthen the community and build the project around people with the same mindset and common interests.The amount of royalties was fixed at 7% to finance the Scrambled projects and other projects we will be launching soon. 
            </Accordion>
          </Box>
          <Box>
            <Accordion title="HOW CAN I USE MY NFT?">
            You will be able to use your NFT as an avatar in the Metaverse and our future video game. Holding also means that you are part of an exclusive network of investors, supporters and entrepreneurs.
            </Accordion>
            <Accordion title="WHAT ARE THE SCRAMBLED?">
            The Scrambled are a set of carefully taken pictures which were digitally designed and edited to look unique and rare.
            </Accordion>
            <Accordion title="WHEN IS THE REVEALING?">
            We will reveal all NFT immediately after we sell out all NFT, we are doing this so as to be fair with everyone minting. So you won't know what you are minting or how rare what you are minting until everyone has minted.
            </Accordion>
          </Box>
        </Container>
     </Section>
  );
}

export default Faq;
