import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';


const LogoDesign = styled.h1`
   font-family: 'Akaya Kanadaka', cursive;
   font-size: ${props => props.theme.fontxxl};
   color: ${props => props.theme.text};
   transition: all 0.2s ease;
   &:hover{
        transform: scale(1.1);
   }
 @media (max-width: 64em) {
  font-size: ${props => props.theme.fontxl};
 }
`


const Logo = () => {
  return (
    <LogoDesign>
       <Link to="/">
       TSCrmbd.
       </Link>
     </LogoDesign>
  );
}

export default Logo;
