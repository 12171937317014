import React, { useLayoutEffect } from 'react';
import gsap from 'gsap';
import { useRef } from 'react';
import ScrollTrigger from 'gsap/ScrollTrigger';
import styled from 'styled-components';
//import Accordion from '../Accordion';
import ConfettiComponent from '../Confetti';
import CountdownCircleTimer from '../CountDownTimer' 

//import { CountdownCircleTimer } from "react-countdown-circle-timer";
//import React from 'react';
//import ReactDOM from 'react-dom';
//import Countdown from 'react-countdown';


const Section = styled.section`
min-height: auto;
width: 100vw;
background-color: ${props => props.theme.text};
position: relative;
color: ${props => props.theme.body};
//display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`
const Title = styled.h1`
font-size: ${(props) => props.theme.fontxxl};
text-transform: capitalize;
color: ${(props) => props.theme.body};
margin: 1rem auto;
border-bottom: 2px solid ${(props) => props.theme.carouselColor};
width: fit-content;
`
const SubTitle = styled.h1`
font-size: ${(props) => props.theme.fontxl};
text-transform: capitalize;
color: ${(props) => props.theme.body};
margin: 1rem auto;
//border-bottom: 2px solid ${(props) => props.theme.carouselColor};
width: fit-content;
`

const Container = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-content: center;

@media (max-width: 64em) {
  width: 80%;
}
@media (max-width: 48em) {
  width: 90%;
  flex-direction: column;

  &>*:last-child{
    &>*:first-child{
      margin-top: 0;
    }
  }
}
`
const Box = styled.div`
width: 45%;

@media (max-width: 64em) {
  width: 90%;
  align-self: center;
}
`




const CountDown = () => {
  const ref = useRef(null);
    gsap.registerPlugin(ScrollTrigger);
    useLayoutEffect(() => {
//      let element = ref.current;
    }, []) 

return (
    <Section ref={ref} id="CountDown">
        <Title>   </Title>
        <ConfettiComponent />
        <SubTitle> Launching In...</SubTitle>
        <CountdownCircleTimer />
        <Container>

        </Container>

     </Section>
  );

}

export default CountDown;