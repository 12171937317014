import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import {dark} from '../styles/Themes';
//import img1 from '../assets/Nfts/bighead.svg';
//import img2 from '../assets/Nfts/bighead-1.svg';
//import img3 from '../assets/Nfts/bighead-2.svg';
//import img4 from '../assets/Nfts/bighead-3.svg';
//import img5 from '../assets/Nfts/bighead-4.svg';
//import img6 from '../assets/Nfts/bighead-5.svg';
import img1 from '../assets/Nfts/scrambled.gif';
import img2 from '../assets/Nfts/scrambled-1.gif';
import img3 from '../assets/Nfts/scrambled-2.gif';
import img4 from '../assets/Nfts/scrambled-3.gif';
import img5 from '../assets/Nfts/scrambled-4.gif';
//import img6 from '../assets/Nfts/scrambled-5.gif';
//import img7 from '../assets/Nfts/scrambled-6.gif';
import img8 from '../assets/Nfts/scrambled-7.gif';
import Button from './Button';
//import img9 from '../assets/Nfts/scrambled-8.gif';
//import Button from './Button';

const ImageContainer = styled.div`
width: 100%;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);

display: flex;
justify-content: center;
align-items: center;
opacity: 00.2;

img{
    width: 15rem;
    height: auto;
}

@media (max-width: 48em) {
    img{
    width: 10rem;
    height: auto;
}
}
`

const Section = styled.section`
width: 100vw;
height: 25rem;
position: relative;
border-top: 2px solid ${props => props.theme.text};
border-top: 2px solid ${props => props.theme.text};

background-color: ${props => `rgba(${props.theme.textRgba},0.9)`};
//color: ${props => props.theme.text};

display: flex;

justify-content: center;
align-items: center;
overflow: hidden;
//flex-direction: column;
@media (max-width: 48em) {
    height: 15rem;
    flex-direction: column;
}
`

const Title = styled.h1`
font-size: ${props => props.theme.fontxxxl};
color: ${props => props.theme.body};
padding: 1rem 2rem;
z-index: 10;
width: 35%;
text-transform: capitalize;
text-shadow: 1px 1px 2px ${props => props.theme.text};

@media (max-width: 64em) {
    font-size: ${props => props.theme.fontxxl};
    text-align: center;
    width: 40%;
}
@media (max-width: 48em) {
    font-size: ${props => props.theme.fontxl};
    padding:2rem 0 ;
    width: 100%;
}
`

const ButtonContainer = styled.div`
width: 35%;
display: flex;
justify-content: flex-end;
@media (max-width: 48em) {
    width: 100%;
    justify-content: center;
}
`
const JoinNow = styled.button`
display: inline-block;
background-color: ${props => props.theme.body};
color: ${props => props.theme.text};
outline: none;
border: none;
font-size: ${props => props.theme.fontlg};
font-weight: 600;
padding: 1.5rem 3rem;
border-radius: 50px;
cursor: pointer;
transition: all 0.2s ease;
position: relative;

@media (max-width: 48em) {
    padding: 1rem 2rem;
}
@media (max-width: 30em) {
    padding: 0.5rem 2rem;
    font-size: ${props => props.theme.fontsm};
}

&:hover{
    transform: scale(0.9);
}

&::after{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border: 2px solid ${props => props.theme.body};
    width: 100%;
    height: 100%;
    border-radius: 50px;
/*    content: '';*/
    transition: all 0.2s ease;
}

&:hover::after{
    transform: translate(-50%, -50%) scale(1);
    padding: 0.3em;
}
`


const Banner = () => {
  return (
    <Section>
        <ImageContainer>
            <img src={img1} alt="The Scrambled"/>
            <img src={img2} alt="The Scrambled"/>
            <img src={img3} alt="The Scrambled"/>
            <img src={img4} alt="The Scrambled"/>
            <img src={img5} alt="The Scrambled"/>
            <img src={img8} alt="The Scrambled"/>
        </ImageContainer>
        <Title>Join Us<br /> On Discord</Title>
        <ButtonContainer>
        <ThemeProvider theme={dark}>
        <Button text="Join Now" link="https://discord.gg/cFjUcV7k59" />
        </ThemeProvider>
        </ButtonContainer>
     </Section>
  );
}
/*
<JoinNow>
Join Now
</JoinNow>
*/
export default Banner;
