//import logo from './logo.svg';
//import './App.css';
import { ThemeProvider } from "styled-components";
import Footer from "./components/Footer";
import Navigation from "./components/Navigation";
import ScrollToTop from "./components/ScrollToTop";
import About from "./components/sections/About";
import CountDown from "./components/sections/CountDown";
import Faq from "./components/sections/Faq";
import Home from "./components/sections/Home";
import Roadmap from "./components/sections/Roadmap";
import Adventure from "./components/sections/Adventure";
//import Showcase from "./components/sections/Showcase";
import Team from "./components/sections/Team";
import GlobalStyles from "./styles/GlobalStyles";
import { light } from "./styles/Themes";

/**TODO: Showcase <Showcase />Will Be Immediately After RoadMap and Should Replace the CountDown after launch */
/** TODO: CountDown <CountDown />  Will Be Immediately After Showcase and Should Replace the CountDown after launch **/
/** TODO: Team <Team />  Will Be Immediately After CountDown and Should Replace the CountDown after launch **/

function App() {
  return (
    <div>
      <GlobalStyles/>
        <ThemeProvider theme = {light}>
        <Navigation />
        <Home />
        <About />
        <Roadmap />
        
        <Adventure />

        <Faq />
        <Footer />
        <ScrollToTop />
        </ThemeProvider>
     </div>
  );
}

export default App;
