import React, {useRef} from 'react';
import gsap from 'gsap';
//import ScrollTrigger from 'gsap/ScrollTrigger'
import { useLayoutEffect } from 'react';
import styled from 'styled-components';
import DrawSvg from '../DrawSvg';
import ConfettiComponent from '../Confetti';

const Section = styled.section`
min-height: 100vh;
width: 100vw;
background-color: ${(props) => props.theme.body};
position: relative;
`;

const Title = styled.h1`
font-size: ${(props) => props.theme.fontxxl};
text-transform: capitalize;
color: ${(props) => props.theme.text};
display: flex;
justify-content: center;
align-items: center;
margin: 1rem auto;
border-bottom: 2px solid ${(props) => props.theme.text};
width: fit-content;

@media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
}
`;

const Container = styled.div`
width: 70%;
height: 200vh;
background-color: ${(props) => props.theme.body};
margin: 0 auto;
display: flex;
justify-content: center;
align-items: center;
position: relative;
@media (max-width: 64em) {
    width: 80%;
}
@media (max-width: 48em) {
    width: 90%;
}
`;
const DrawSvgContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
`;
const Items = styled.ul`
list-style: none;
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
//background-color: lightcoral;

@media (max-width: 48em) {
    width: 90%;
}

&>*:nth-of-type(2n +1){
  justify-content: start;
  @media (max-width: 48em) {
    justify-content: center;
  }
  div{
    border-radius: 50px 0 50px 0;
    text-align: right;

    @media (max-width: 48em) {
      border-radius: 0 50px 0 50px;
    text-align: left;
    //justify-content: center;
    p{
      border-radius: 0 40px 0 40px;
     }
   }
  }
  p{
    border-radius: 40px 0 40px 0;
  }
}
&>*:nth-of-type(2n){
  justify-content: end;
  @media (max-width: 48em) {
    justify-content: center;
  }
  div{
    border-radius: 0 50px 0 50px;
    text-align: left;

  }
  p{
    border-radius: 0 40px 0 40px;
  }
}
`;
const Item = styled.li`
width: 100%;
height: 100%;
display: flex;

@media (max-width: 48em) {
    justify-content: flex-end !important;
}
`;
const ItemContainer = styled.div`
width: 40%;
height: fit-content;
padding: 1rem;
Border: 3px solid ${(props) => props.theme.text};

@media (max-width: 48em) {
    width: 70%
}
`;
const Box = styled.p`
height: fit-content;
background-color: ${(props) => props.theme.carouselColor};
color: ${(props) => props.theme.text};
padding: 1rem;
position: relative;
border: 1px solid ${(props) => props.theme.text};
`;
const Subtitle = styled.span`
display: block;
font-size: ${(props) => props.theme.fontxl};
text-transform: capitalize;
color: ${(props) => props.theme.text};

@media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontlg};
    font-weight: 600;
}
`;
const Text = styled.span`
display: block;
font-size: ${(props) => props.theme.fontsm};
text-transform: capitalize;
color: ${(props) => props.theme.text};
font-weight: 400;
margin: 0.5rem 0;

@media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxs};
}
`;


const RoadMapItem =({title, subtext, addToRef}) => {
      return(
        <Item ref = {addToRef}>
          <ItemContainer>
            <Box>
              <Subtitle>{title}</Subtitle>
              <Text>{subtext}</Text>
            </Box>
          </ItemContainer>
        </Item>
      )

}

const Roadmap = () => {

  const revealRefs = useRef([]);
  revealRefs.current = [];
  //gsap.registerPlugin(scrollTrigger);

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)){
      revealRefs.current.push(el);
    }
  }

  useLayoutEffect(() => {
    //console.log(revealRefs)
    let t1 = gsap.timeline();
  revealRefs.current.forEach((el, index) => {
    t1.fromTo(
      el.childNodes[0],
      {
        y: '0'
      },{
        y: '-30%',

        scrollTrigger:{
          id: `section-${index + 1}`,
          trigger: el,
          start: 'top center+=200px',
          end: 'bottom center',
          scrub: true,
          //markers: true,
        }
      }
    )
  })  
  return () => {};
}, [])


  return (
    <Section id="roadmap">
	<ConfettiComponent />
      <Title>
        Roadmap
      </Title>
      <Container>
        <DrawSvgContainer>
         <DrawSvg />  
        </DrawSvgContainer>  
        <Items>
          <Item>&nbsp;</Item>
          <RoadMapItem addToRef={addToRefs} title="The Minting" subtext="White Listed accounts starts minting at about 90% below launch price, this is to appreciate our early supporters. Reveal"/>
          <RoadMapItem addToRef={addToRefs} title="Community Gain" subtext="All Scrambled holder will gain automatic access to all upcoming projects. Reveal after 100% mint"/>
          <RoadMapItem addToRef={addToRefs} title="Development Support" subtext="60% of total sales and royalties will be in support of the NFT and future  project development."/>
          <RoadMapItem addToRef={addToRefs} title="Educational Support" subtext="40% from sales and royalties will be for educational support."/>
          <RoadMapItem addToRef={addToRefs} title="Next Project" subtext="After a succesful launch, we can begin planning on our project."/>

        </Items>
      </Container>  
     </Section>
  );
}

export default Roadmap;
