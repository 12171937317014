import React from 'react';
import styled from 'styled-components';
import Typewriter from 'typewriter-effect';
import Button from './Button';


const Title = styled.h2`
font-size: ${props => props.theme.fontxxl};
text-transform: capitalize;
width: 80%;
color: ${props => props.theme.text};
align-self: flex-start;

 span{
     text-transform: uppercase;
     font-family: 'Akaya Kanadaka', cursive;
  }
  .text-1{
      color: blue;
  }
  .text-2{
      color: red;
  }
  .text-3{
      color: orange;
  }
  .text-4{
      color: purple;
  }
 
  @media (max-width: 70em) {
    font-size: ${(props) => props.theme.fontxl};
  }
  @media (max-width: 48em) {
    align-self: center;
    text-align: center;
  }
  @media (max-width: 40em) {
    width: 90%;
  }
`
const Subtitle = styled.h3`
    font-size: ${(props) => props.theme.fontlg};
    text-transform: capitalize;
    color: ${props => `rgba(${props.theme.textRgba}, 0.6)`};
    font-weight: 600;
    margin-bottom: 1rem;
    width: 80%;
    align-self: flex-start;
    @media (max-width: 40em) {
        font-size: ${(props) => props.theme.fontmd};
    }
    @media (max-width: 48em) {
        align-self: center;
        text-align: center;
    }
`
const ButtonContainer = styled.div`
    width: 80%;
    align-self: flex-start;
    
    @media (max-width: 48em) {
        align-self: center;
        text-align: center;
        button{
            margin: 0 auto;
        }
    }

`

const TypeWriterText = () => {
  return (
   <>
     <Title>
        Discover the Scrambled nft from us
        <Typewriter
        options={{autostart: true, loop: true}}
        onInit={(typewriter) => {
            typewriter.typeString('<span class="text-1">NFTs.</span>')
            .pauseFor(2000)
            .deleteAll()
            typewriter.typeString('<span class="text-2">Arts.</span>')
            .pauseFor(2000)
            .deleteAll()
            typewriter.typeString('<span class="text-3">Photography.</span>')
            .pauseFor(2000)
            .deleteAll()
            typewriter.typeString('<span class="text-4">Collectibles.</span>')
            .pauseFor(2000)
            .deleteAll()
            .start();
        }}
        />
     </Title>
   <Subtitle>The Scrambled NFT, Lets unscramble something new</Subtitle>
   <ButtonContainer>
        <Button text="Mint Now" link="https://mint.thescrambled.io" />   
   </ButtonContainer>       
  </>

);
}

export default TypeWriterText;
